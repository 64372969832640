export const TASK_STATUS_DONE = 'done';
export const TASK_STATUS_INCOMPLETE = 'incomplete';
export const TASK_STATUS_BLOCKED = 'hide_blocked_tasks';
export const TASK_STATUS_ALL = 'all';
export const TASK_STATUS_OPTIONS = [
  { value: TASK_STATUS_DONE, label: 'Done' },
  { value: TASK_STATUS_INCOMPLETE, label: 'Incomplete' }
];
export const HIDE_BLOCKED_TASK_OPTION = { value: true, label: 'Hide blocked tasks' };
export const INCLUDE_BLOCKED_TASK_OPTION = { value: false, label: 'Include blocked tasks' };
export const TASK_AVAILABILITY_OPTIONS = [HIDE_BLOCKED_TASK_OPTION, INCLUDE_BLOCKED_TASK_OPTION];
